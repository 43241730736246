"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const react_redux_1 = require("react-redux");
const SerchContext_1 = require("../context/SerchContext");
const InfoCliente_1 = require("../context/InfoCliente");
const carrinho_1 = require("@/redux/carrinho");
const PedidoContext_1 = require("../context/PedidoContext");
const TopMenuNav = (props) => {
    var _a, _b;
    const [active, setActive] = (0, react_1.useState)(-1);
    const { loja } = (0, react_1.useContext)(InfoCliente_1.InfoClienteContext);
    const { pedidoNumber } = (0, react_1.useContext)(PedidoContext_1.PedidoContext);
    const { search, setSearch } = (0, react_1.useContext)(SerchContext_1.SearchContext);
    const carrinho = props.carrinho.carrinho[0] ? props.carrinho.carrinho[0] : null;
    (0, react_1.useEffect)(() => {
        if (window.location.pathname !== '/pedido') {
            props.getSaldos(pedidoNumber);
        }
    }, []);
    const handleExpand = (e, i) => {
        e.preventDefault();
        setActive(active === i ? -1 : i);
    };
    return (react_1.default.createElement("div", { className: 'w-100 d-flex align-items-center flex-column ', style: { maxHeight: "110px" } },
        react_1.default.createElement("div", { className: 'w-100 d-flex justify-content-between' },
            react_1.default.createElement("div", { className: 'voltar' }, window.location.pathname === '/pedido' ?
                react_1.default.createElement(react_router_dom_1.Link, { to: '/home' },
                    react_1.default.createElement("span", null,
                        react_1.default.createElement("i", { className: "fa fa-angles-left" }),
                        " ",
                        react_1.default.createElement("span", null, " Portal ")))
                : window.location.pathname === '/market' ?
                    react_1.default.createElement(react_router_dom_1.Link, { to: '/pedidos' },
                        react_1.default.createElement("span", null,
                            react_1.default.createElement("i", { className: "fa fa-angles-left" }),
                            " ",
                            react_1.default.createElement("span", null, " Selecionar Pedido")))
                    :
                        react_1.default.createElement(react_router_dom_1.Link, { to: '/market' },
                            react_1.default.createElement("span", null,
                                react_1.default.createElement("i", { className: "fa fa-angles-left" }),
                                " ",
                                react_1.default.createElement("span", null, " Loja")))),
            window.location.pathname !== '/pedidos' &&
                react_1.default.createElement("div", { className: 'esc-loja' },
                    react_1.default.createElement("span", null, loja.razao_social),
                    react_1.default.createElement("span", null, loja.cnpj)),
            window.location.pathname === '/market' &&
                react_1.default.createElement("div", { className: "search" },
                    react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("span", null, "Busca: "),
                        react_1.default.createElement("input", { type: "text", value: search, onChange: (e) => {
                                setSearch(e.target.value);
                            } }))),
            window.location.pathname !== '/pedidos' &&
                react_1.default.createElement("div", { className: 'cart' },
                    react_1.default.createElement(react_router_dom_1.Link, { to: '/carrinho' },
                        react_1.default.createElement("div", { className: carrinho ? ((_a = carrinho.itens) === null || _a === void 0 ? void 0 : _a.length) > 0 ? "badge" : 'd-none' : 'd-none' }, carrinho ? (_b = carrinho.itens) === null || _b === void 0 ? void 0 : _b.length : null),
                        react_1.default.createElement("i", { className: "fa fa-cart-shopping" }))))));
};
const mapStateToprops = (state) => {
    return {
        carrinho: state.carrinho,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getSaldos: (pedidoNumber) => { dispatch(carrinho_1.actionCreators.getCarrinhoProdutos.call(pedidoNumber)); }
});
exports.default = (0, react_redux_1.connect)(mapStateToprops, mapDispatchToProps)(TopMenuNav);
